<script>
import MainDocument from "@/components/helpers/MainDocument.vue";

export default {
  name: "DocumentsVideo",

  components: { MainDocument },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<template>
  <div class="documents-video">
    <h5 v-if="data.subtitle">{{ data.subtitle }}</h5>
    <p v-if="data.description">{{ data.description }}</p>

    <iframe
      v-if="data.video_url"
      width="100%"
      height="344"
      :src="`https://www.youtube.com/embed/${data.video_url};controls=0`"
    />

    <a
      v-if="data.download_url && data.download_label"
      class="documents-video__link"
      :href="data.download_url"
      target="_blank"
    >
      <img src="../../assets/icons/yandex_disk.svg" alt="" />
      <p>{{ data.download_label || "Скачать видео" }}</p>
      <span class="icon-arrow-line-medium" />
    </a>

    <div
      v-if="data.attachment && data.attachment.length"
      class="documents-video__attachments"
    >
      <main-document
        v-for="(document, index) in data.attachment"
        :key="index"
        :title="document.original_name"
        :description="document.description"
        :url="document.url"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.documents-video {
  margin: 24px 0 0;
  > h5 {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 12px;
  }

  > p {
    @include body-1;
    color: $dark-primary;
    margin-bottom: 40px;
  }

  > iframe {
    border: none;
  }

  &__link {
    cursor: pointer;
    color: $blue;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
    @include body-1-bold;
    text-decoration: none;
  }

  .icon-arrow-line-medium {
    background-color: $blue;
    width: 24px;
    height: 24px;
  }

  &__attachments {
    margin: 40px 0 0;
  }
}
</style>
