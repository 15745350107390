<script>
import { mapState, mapActions } from "vuex";
import { DOCUMENT_SECTIONS } from "@/package/const/document-sections.js";
import MainTitleBack from "@/components/helpers/MainTitleBack.vue";
import MainSelect from "@/components/helpers/MainSelect.vue";
import MainDocument from "@/components/helpers/MainDocument.vue";
import MainLoader from "@/components/helpers/MainLoader.vue";
import MainCollapse from "@/components/helpers/MainCollapse.vue";
import DocumentsVideo from "@/components/documents/DocumentsVideo.vue";

export default {
  components: {
    DocumentsVideo,
    MainCollapse,
    MainTitleBack,
    MainSelect,
    MainDocument,
    MainLoader,
  },

  data() {
    return {
      documents: null,
      rdo_documents: null,
      DOCUMENT_SECTIONS: DOCUMENT_SECTIONS,
      loadingDocuments: true,

      selectedOption: null,
      selectedSection: [],

      select_rdo_section: "",
    };
  },

  watch: {
    selectedOption: {
      deep: true,
      handler(newValue) {
        this.select_rdo_section = "";
        this.selectedSection = this.DOCUMENT_SECTIONS.find((section) => {
          return section.id === newValue.id;
        });

        this.$router.push({ path: `${this.selectedSection.id}` });
      },
    },

    selectedSection: {
      deep: true,
      handler() {
        this.selectedOption = this.formattedListOfSections[
          this.indexOfSelectedSection
        ];
      },
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),

    formattedListOfSections() {
      return this.DOCUMENT_SECTIONS.map((section) => {
        return {
          id: section.id,
          name: section.title,
        };
      });
    },

    indexOfSelectedSection() {
      return this.formattedListOfSections.findIndex((section) => {
        return section.id === this.selectedSection.id;
      });
    },

    disabledArrowLeft() {
      return this.indexOfSelectedSection === 0;
    },

    disabledArrowRight() {
      return this.indexOfSelectedSection + 1 === this.DOCUMENT_SECTIONS.length;
    },
  },

  methods: {
    ...mapActions({
      loadDocuments: "loadDocuments",
      loadRdoDocuments: "loadRdoDocuments",
    }),

    changeSection(indexOfSection) {
      if (
        indexOfSection >= 0 &&
        indexOfSection < this.DOCUMENT_SECTIONS.length
      ) {
        this.selectedSection = this.DOCUMENT_SECTIONS[indexOfSection];
        this.$router.push({ path: `${this.selectedSection.id}` });
      }
    },

    selectRdoSection(index) {
      if (this.select_rdo_section === index + 1) {
        this.select_rdo_section = "";
        return;
      }

      this.select_rdo_section = index + 1;
    },
  },

  beforeMount() {
    const indexOfSection = this.formattedListOfSections.findIndex((section) => {
      return section.id === this.$route.params.id;
    });

    this.selectedOption = this.formattedListOfSections[indexOfSection];

    this.loadDocuments({ page: "documents" })
      .then((response) => {
        this.documents = response.data;

        if (this.selectedOption.id !== "rdo") {
          this.selectedSection = this.DOCUMENT_SECTIONS[indexOfSection];
        }
      })
      .finally(() => {
        this.loadingDocuments = false;
      });

    this.loadRdoDocuments()
      .then((response) => {
        this.rdo_documents = response.data.data;

        if (this.selectedOption.id === "rdo") {
          this.selectedSection = this.DOCUMENT_SECTIONS[indexOfSection];
        }
      })
      .finally(() => {
        this.loadingDocuments = false;
      });
  },

  created() {
    if (
      (this.user.tariff?.code !== "vip_d" &&
        this.user.tariff?.code !== "vip_gold_d") ||
      this.user.tariff === null
    ) {
      this.DOCUMENT_SECTIONS = DOCUMENT_SECTIONS.filter((section) => {
        return section.id !== "tsp";
      });
    }
  },
};
</script>

<template>
  <div class="documents-section default-content-padding">
    <main-title-back
      class="documents-section__title"
      :title="selectedOption.name"
      href="Documents"
    />

    <div class="documents-section__section-selector">
      <span
        class="documents-section__arrow"
        :class="{ 'documents-section__arrow_disabled': disabledArrowLeft }"
        @click="changeSection(indexOfSelectedSection - 1)"
      ></span>

      <main-select
        :selected-option.sync="selectedOption"
        :options="formattedListOfSections"
        style-type="background-white"
      />

      <span
        class="documents-section__arrow"
        :class="{ 'documents-section__arrow_disabled': disabledArrowRight }"
        @click="changeSection(indexOfSelectedSection + 1)"
      ></span>
    </div>

    <main-loader v-if="loadingDocuments" />

    <div
      v-if="!loadingDocuments && documents && selectedSection.id !== 'rdo'"
      class="documents-section__documents"
    >
      <main-document
        v-for="document in documents[selectedSection.id]"
        :key="document.id"
        :title="document.original_name"
        :description="document.description"
        :url="document.url"
      />
    </div>

    <div
      v-if="!loadingDocuments && rdo_documents && selectedSection.id === 'rdo'"
      class="documents-section__collapse-list"
    >
      <MainCollapse
        v-for="(section, index) in rdo_documents"
        :key="index"
        :title="section.title"
        auto-close
        :select="select_rdo_section === index + 1"
        @select="selectRdoSection(index)"
      >
        <DocumentsVideo :data="section" />
      </MainCollapse>
    </div>
  </div>
</template>

<style lang="scss">
.documents-section {
  padding-top: $space-xxl;
  padding-bottom: $space-xxl;

  &__title {
    margin-top: $space-m;
    margin-bottom: $space-xxxl;
  }

  &__section-selector {
    max-width: 660px;
    margin-bottom: 40px;
    display: grid;
    align-items: center;
    grid-template-columns: 16px 1fr 16px;
    column-gap: $space-l;

    .main-select {
      &__items {
        max-width: 100% !important;
      }

      &_background-white {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__arrow {
    cursor: pointer;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: $dark-fourth;
    mask: url("../../assets/icons/arrow_bold.svg");
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    &:first-child {
      transform: rotate(180deg);
    }

    &_disabled {
      cursor: not-allowed;
      background: $light-sixth;

      &:hover,
      &:active {
        background: $light-sixth !important;
      }
    }

    &:hover {
      background: $dark-primary;
    }

    &:active {
      background: $dark-fourth;
    }
  }

  .main-loader {
    max-width: 660px;
  }

  &__documents {
    max-width: 660px;
    background-color: $light-primary;
    padding: $space-xl;
    border-radius: $space-s;

    .main-document__wrapper {
      margin-right: 0 !important;
    }
  }

  &__collapse-list {
    max-width: 660px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
  }

  &__collapse-item {
    padding-top: 24px;
    @include body-1();
    color: $dark-primary;
  }
}

@media (max-width: 424px) {
  .documents-section {
    padding-bottom: $space-xl;
  }
}
</style>
